<template>
  <div class="content non-existing-site">
    <div class="vertical-middle min-vh-100">
      <div class="heading-block center nobottomborder">
        <h1>{{ $t('non-existing-site.title') }}</h1>
      </div>
    </div>
  </div>
</template>

<style>
  .non-existing-site .heading-block::after {
    display:none;
  }

  .non-existing-site h1 {
    text-shadow: 2px 2px 2px #AAAAAA;
  }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mapGetters } from '@fwk-node-modules/vuex';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  computed: {
      ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
        })
  }
})
export default class NonExistingSite extends Vue {

 
}
</script>